import styled from 'styled-components';

export const AddToCartButton = styled.button`
    width:100%;
    height: 62px;
    background: #E26447;
    border-radius: 8px;
    border: none;
    font-family: P22 Underground;
    font-weight: 600;
    font-size: 18px;
    line-height: 22px;
    letter-spacing: 0.1em;
    text-transform: uppercase;
    color: #FFFFFF;
    outline:none;
    
        &:hover {background: #B45038}

        &:active {
        background: #B45038;
        box-shadow: 0 5px #666;
        transform: translateY(4px);
    }
    
}
`;
