import React from 'react';
import * as S from './AddToCartButtonV3.styles';

const AddToCartButton = props => {
  const { buttonText } = props;

  return <S.AddToCartButton>{buttonText}</S.AddToCartButton>;
};

export default AddToCartButton;
